:root {
    --primary: #AC77EF !important;
}
a.btn.btn-primary,a.btn.btn-primary:hover{
    background: var(--primary) !important;
}

li {
    list-style-type: none;
}
img.img-fluid {
    width: 100%;
    object-fit: cover;
}
.multiple-field-position {
    position: relative;
}
button.mutiple-field-add,button.mutiple-field-remove{
    position: absolute;
    top: 3.5rem;
    right: 1rem;
    border: none;
    background: none;
    font-size: 1.25rem;
}
button.mutiple-field-add{
    color: var(--primary);   
}
button.mutiple-field-remove {
    top: 1rem !important;
    right: 1rem !important;
    color: red;
}

@media (max-width:768px) {
    .small-images{
        overflow-x: scroll;
    }
}

.thumbImage {
    position: relative;
    width: 100px;
    height: 60px;
    transition: all .5s;
  }

  .thumbImage img {
    width: 100px;
    height: 100%;
    object-fit: cover;
  }
  
  .thumbImage .packageCancelButton {
    position: absolute;
    /* width: 10px; */
    /* height: 10px; */
    right: 0;
    top: 0;
    background-color: red;
    border: 1px solid #4a4a4a78;
    border-radius: 100px;
    color: #fff;
    font-weight: bold;
    opacity: 1;
    cursor: pointer;
    outline: none;
  }
  